import Api from '../../../common/services/api';
import * as exchangeUtil from '../utils/exchange';

export const FLEXCHANGE_DECLINE_EXCHANGE = 'flexchange/DECLINE_EXCHANGE';

export default (exchangeId) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const payload = { action: 'decline' };
  const { data } = await Api.patch(`/v2/networks/${selected}/exchanges/${exchangeId}`, payload);

  return dispatch({
    type: FLEXCHANGE_DECLINE_EXCHANGE,
    exchangeId,
    exchange: exchangeUtil.transfromExchange(data),
  });
};
