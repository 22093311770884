import moment from 'moment';
import Api from '../../../common/services/api';
import { ActivityTypes } from '../utils/activity';
import * as exchangeUtil from '../utils/exchange';
import createId from '../../../common/utils/create-temp-id';

export const FLEXCHANGE_ACCEPT_EXCHANGE = 'flexchange/ACCEPT_EXCHANGE';

const createAcceptAcitity = (exchangeId, loggedUser) => ({
  type: 'activity',
  data: {
    id: createId(), // TODO: replace with actual id
    activity_type: ActivityTypes.EXCHANGE_ACCEPTED,
    date: moment().toISOString(),
    meta_data: {},
    source_id: exchangeId,
    user: loggedUser,
  },
});

export default (exchangeId) => async (dispatch, getState) => {
  const { network: { selected }, users, loggedUser } = getState();

  const payload = { action: 'accept' };
  const { data } = await Api.patch(`/v2/networks/${selected}/exchanges/${exchangeId}`, payload);

  return dispatch({
    type: FLEXCHANGE_ACCEPT_EXCHANGE,
    exchangeId,
    exchange: exchangeUtil.transfromExchange(data),
    activity: createAcceptAcitity(exchangeId, users.items[loggedUser.user.id]),
  });
};
